import {
  DEFAULT_BLACK_IMAGE_PATH,
} from '../../../scripts/Routes/images.js';

export const movies2020 = [
  {
    'src': DEFAULT_BLACK_IMAGE_PATH,
    'text': 'Tenet',
    'url': '#',
  },
  {
    'src': DEFAULT_BLACK_IMAGE_PATH,
    'text': 'Idiocracy',
    'url': '#',
  },
  {
    'src': DEFAULT_BLACK_IMAGE_PATH,
    'text': 'Due Date',
    'url': '#',
  },
  {
    'src': DEFAULT_BLACK_IMAGE_PATH,
    'text': 'Mulan',
    'url': '#',
  },
  {
    'src': DEFAULT_BLACK_IMAGE_PATH,
    'text': 'Citizen Kane',
    'url': '#',
  },
  {
    'src': DEFAULT_BLACK_IMAGE_PATH,
    'text': 'Walk the Line',
    'url': '#',
  },
  {
    'src': DEFAULT_BLACK_IMAGE_PATH,
    'text': 'American History X',
    'url': '#',
  },
  {
    'src': DEFAULT_BLACK_IMAGE_PATH,
    'text': 'Project Power',
    'url': '#',
  },
  {
    'src': DEFAULT_BLACK_IMAGE_PATH,
    'text': 'Richard Jewell',
    'url': '#',
  },
  {
    'src': DEFAULT_BLACK_IMAGE_PATH,
    'text': 'American Pickle',
    'url': '#',
  },
  {
    'src': DEFAULT_BLACK_IMAGE_PATH,
    'text': 'Kobe Bryants Muse',
    'url': '#',
  },
  {
    'src': DEFAULT_BLACK_IMAGE_PATH,
    'text': 'Mission Impossible 4',
    'url': '#',
  },
  {
    'src': DEFAULT_BLACK_IMAGE_PATH,
    'text': 'The Firm',
    'url': '#',
  },
  {
    'src': DEFAULT_BLACK_IMAGE_PATH,
    'text': 'Hell or High Water',
    'url': '#',
  },
  {
    'src': DEFAULT_BLACK_IMAGE_PATH,
    'text': 'Lake House',
    'url': '#',
  },
  {
    'src': DEFAULT_BLACK_IMAGE_PATH,
    'text': 'Walk Hard: The Dewey Cox Story',
    'url': '#',
  },
  {
    'src': DEFAULT_BLACK_IMAGE_PATH,
    'text': 'The Devils Advocate',
    'url': '#',
  },
  {
    'src': DEFAULT_BLACK_IMAGE_PATH,
    'text': 'Point Break',
    'url': '#',
  },
  {
    'src': DEFAULT_BLACK_IMAGE_PATH,
    'text': 'Taxi Driver',
    'url': '#',
  },
  {
    'src': DEFAULT_BLACK_IMAGE_PATH,
    'text': 'Ad Astra',
    'url': '#',
  },
  {
    'src': DEFAULT_BLACK_IMAGE_PATH,
    'text': 'The Ballad of Buster Scruggs',
    'url': '#',
  },
  {
    'src': DEFAULT_BLACK_IMAGE_PATH,
    'text': 'Wild Hogs',
    'url': '#',
  },
  {
    'src': DEFAULT_BLACK_IMAGE_PATH,
    'text': 'Rolling Thunder',
    'url': '#',
  },
  {
    'src': DEFAULT_BLACK_IMAGE_PATH,
    'text': 'Hateful Eight',
    'url': '#',
  },
  {
    'src': DEFAULT_BLACK_IMAGE_PATH,
    'text': 'Man of Tai Chi',
    'url': '#',
  },
  {
    'src': DEFAULT_BLACK_IMAGE_PATH,
    'text': 'Side by Side',
    'url': '#',
  },
  {
    'src': DEFAULT_BLACK_IMAGE_PATH,
    'text': 'Uncut Gems',
    'url': '#',
  },
  {
    'src': DEFAULT_BLACK_IMAGE_PATH,
    'text': 'Parasite',
    'url': '#',
  },
  {
    'src': DEFAULT_BLACK_IMAGE_PATH,
    'text': 'Knives Out',
    'url': '#',
  },
  {
      'src': DEFAULT_BLACK_IMAGE_PATH,
      'text': '1917',
      'url': '#',
  },
  {
    'src': DEFAULT_BLACK_IMAGE_PATH,
    'text': 'Ronny Chieng Destroys America',
    'url': '#',
  },
  {
    'src': DEFAULT_BLACK_IMAGE_PATH,
    'text': 'The Irishman',
    'url': '#',
  },
  {
      'src': DEFAULT_BLACK_IMAGE_PATH,
      'text': '3 Mics - Neal Brennan',
      'url': '#',
  },
];

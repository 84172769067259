import {
  DEFAULT_BLACK_IMAGE_PATH,
} from '../../../scripts/Routes/images.js';

export const books2018 = [
    {
        'src': DEFAULT_BLACK_IMAGE_PATH,
        'text': 'Zero to One - Peter Thiel',
        'url': '#',
    },
    {
        'src': DEFAULT_BLACK_IMAGE_PATH,
        'text': 'Modern Romance - Aziz Ansari',
        'url': '#',
    },
    {
        'src': DEFAULT_BLACK_IMAGE_PATH,
        'text': 'Troublemaker - Leah Remini',
        'url': '#',
    },
    {
        'src': DEFAULT_BLACK_IMAGE_PATH,
        'text': 'Siddhartha - Hermann Hesse',
        'url': '#',
    },
    {
        'src': DEFAULT_BLACK_IMAGE_PATH,
        'text': 'The Simple Path to Wealth - JL Collins',
        'url': '#',
    },
    {
        'src': DEFAULT_BLACK_IMAGE_PATH,
        'text': 'Learning React: Kirupa Chinnathambi',
        'url': '#',
    },
    {
        'src': DEFAULT_BLACK_IMAGE_PATH,
        'text': 'Creativity Inc - Ed Catmull',
        'url': '#',
    },
    {
        'src': DEFAULT_BLACK_IMAGE_PATH,
        'text': 'How to American - Jimmy Yang',
        'url': '#',
    },
];
